@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url('./Assets/bg.png'), linear-gradient(to left, rgb(27, 20, 41), rgb(20, 15, 35));
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
}
